<template>
    <a-card>
        <a-page-header
            :title='"广告主账户余额"'
        />
        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="广告主">
                        <a-input
                            v-model="form.advertiserName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="广告平台">
                        <a-select
                            mode="multiple"
                            v-model="adPlatformIdList"
                            placeholder="请选择"
                            style="width: 100%;"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option v-for="item in platformList" :key="item.id">{{ item.adPlatformName }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="8">
                    <a-form-model-item label="托管权限">
                        <a-select
                            v-model="form.trusteeStatus"
                            placeholder="请选择"
                            style="width: 100%;"
                        >
                            <a-select-option key="2">已托管</a-select-option>
                            <a-select-option key="1">未托管</a-select-option>
                            <a-select-option key="3">无法托管</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="广告主状态">
                        <a-select
                            v-model="form.status"
                            placeholder="请选择"
                            style="width: 100%;"
                        >
                            <a-select-option key="1">开启</a-select-option>
                            <a-select-option key="2">关闭</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="flex-end">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :row-key="(record, index) => index"
            :pagination='false'
        >
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />
    </a-card>
</template>

<script>
export default {
    data() {
        return {
            layout: {
                labelCol: { span: 6 },
                wrapperCol: { span: 18 }
            },
            form: {
                status: '1'
            },
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '广告主编码',
                    dataIndex: 'advertiserCode',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告主',
                    dataIndex: 'advertiserName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告平台',
                    dataIndex: 'adPlatformName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '平台帐套名',
                    dataIndex: 'adPlatformAccountName',
                    customRender(text) {
                        return text || '-'
                    },
                    width: '20%'
                },
                {
                    align: 'center',
                    title: '平台帐套状态',
                    // dataIndex: 'adPlatformAccountAuthStatus',
                    customRender: (text, row) => {
                        return row.adPlatformAccountAuthStatus == 1 ? '授权中' : '已失效'
                    }
                },
                {
                    align: 'center',
                    title: '托管权限',
                    // dataIndex: 'trusteeStatus',
                    customRender: (text, row) => {
                        return row.trusteeStatus == 2 ? '已托管' : row.trusteeStatus == 3 ? '无法托管' : '未托管'
                    }
                },
                {
                    align: 'center',
                    title: '账号余额',
                    dataIndex: 'balance',
                    customRender: (text, row) => {
                        return (text ?? '') !== '' ? <div class="red">{text}</div> : '-'
                    }
                },
                {
                    align: 'center',
                    title: '日预算',
                    dataIndex: 'dailyBudget',
                    customRender: (text, row) => {
                        return (text ?? '') !== '' ? <div>{text}</div> : '-'
                    }
                },
                {
                    align: 'center',
                    title: '昨日消耗',
                    dataIndex: 'yesterdayCharge',
                    customRender: (text, row) => {
                        return (text ?? '') !== '' ? <div>{text}</div> : '-'
                    }
                },
                {
                    align: 'center',
                    title: '本日消耗',
                    dataIndex: 'todayCharge',
                    customRender: (text, row) => {
                        return (text ?? '') !== '' ? <div>{text}</div> : '-'
                    }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            adPlatformIdList: [],
            platformList: [],
        }
    },
    created() {
        this.getDataList()
        this.getAdPlatformList()
    },
    methods: {
        getAdPlatformList() {
            this.$api.core.pphtManage.getAdPlatformList().then(res => {
                if(res.code == 200) {
                    this.platformList = res.data
                } else {
                    console.error(`获取平台列表失败，${res.message}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.adPlatformIdList = []
            this.form = {
                status: '1'
            }
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList() {
            this.loading = true
            let params = Object.assign(this.form, {
                adPlatformIds: this.adPlatformIdList.length > 0 ? this.adPlatformIdList.toString() : undefined,
                page: this.pagination.current,
                size: this.pagination.pageSize
            })
            this.$api.core.pphtManage.getAccountBalanceList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 40px;
}
</style>